import RequestMoreInformationLandingPage from "./lp/interests/v1/request-more-information"
import React from "react"
import { HeadFC } from "gatsby"
import SEO from "../components/seo/seo"

const MoreInformationCopy: React.FC = (): JSX.Element => {
   return (
      <RequestMoreInformationLandingPage/>
   )
}

export default MoreInformationCopy

export const Head: HeadFC = () => (
   <SEO
      title="Request more information | Prestige VIP"
      metaDescription="Get the best prices to the best events across the world with Prestige VIP's concierge service."
      slug="more"
   />
)